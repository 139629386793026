<template>
  <InvoicesBrowse />
</template>

<script>
import InvoicesBrowse from "@/components/invoices/InvoicesBrowse";
export default {
  name: "BrowseInvoices",
  components: { InvoicesBrowse }
};
</script>

<style scoped></style>
