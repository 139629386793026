<template>
  <Page title="Browse Invoices">
    <v-form v-if="hasMultipleClients" @submit.prevent="">
      <v-row dense>
        <v-col>
          <v-select
            :items="clients"
            item-value="id"
            item-text="title_with_id"
            v-model="options.params.client_id"
            label="Client"
            clearable
          />
        </v-col>
      </v-row>
    </v-form>
    <v-data-table v-bind="defaultAttrs" :options.sync="options">
      <template v-slot:item.created="{ item }">
        <span v-if="item.created">{{ formatDate(item.created) }}</span>
      </template>
      <template v-slot:item.due_date="{ item }">
        <span v-if="item.due_date">{{ formatDate(item.due_date) }}</span>
      </template>
      <template v-slot:item.is_paid="{ item }">
        <v-icon :color="item.is_paid ? 'success' : 'warning'">
          {{ item.is_paid ? "mdi-check" : "mdi-progress-clock" }}
        </v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span class="mx-2">
              <v-icon
                @click.stop="() => triggerDownloadInvoice(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-file
              </v-icon>
            </span>
          </template>
          <span>Download PDF</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </Page>
</template>

<script>
import Page from "@/components/layout/Page";
import dataTablePagination from "@/mixins/dataTablePagination";
import dayjs from "dayjs";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "InvoicesBrowse",
  components: { Page },
  mixins: [dataTablePagination],
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Invoice Date", value: "created", dataType: "Date" },
        { text: "Invoice Total", value: "total", dataType: "Date" },
        { text: "Due Date", value: "due_date" },
        { text: "Invoice Paid", value: "is_paid" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      options: {
        params: {
          client_id: ""
        }
      }
    };
  },
  computed: {
    ...mapGetters("clients", ["hasMultipleClients"]),
    ...mapState("clients", { clients: "items" })
  },
  methods: {
    ...mapActions("invoices", [
      "getInvoices",
      "getPaginated",
      "downloadInvoice"
    ]),
    ...mapActions(["notify"]),
    ...mapMutations(["setLoading"]),
    triggerDownloadInvoice(item) {
      this.setLoading({
        message: "Downloading Invoice...",
        opacity: 0.5,
        overlay: true
      });
      this.downloadInvoice({
        invoice_id: item.id
      }).then(() => this.setLoading({}));
    },
    formatDate(value) {
      return value ? dayjs(String(value)).format("MM/DD/YYYY") : "";
    }
  },
  mounted() {
    Promise.all([this.getInvoices()]).then(() => (this.loading = false));
  }
};
</script>

<style scoped></style>
